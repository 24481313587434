<template>
  <div class="layout-container py-40 md:py-56">
    <div v-if="isEditMode" class="mb-24">
      <div
        class="btn btn--dark-theme mr-16 rounded-none px-12"
        data-epi-edit="backgroundMedia"
      >
        Change background media
      </div>
      <div
        class="btn btn--dark-theme mr-16 rounded-none px-12"
        data-epi-edit="BackgroundColor"
      >
        Change background color
      </div>
      <div
        class="btn btn--dark-theme mr-16 rounded-none px-12"
        data-epi-edit="MainBodyPosition"
      >
        Change content position
      </div>
    </div>
    <div class="md:flex">
      <div
        v-if="data && data.BackgroundMedia"
        class="relative w-full bg-center bg-no-repeat md:w-1/2"
        :class="{
          'bg-cover aspect-[927/1050]': !data.DisableBackgroundCover,
          'flex items-center justify-center': data.DisableBackgroundCover,
          'aspect-square !min-h-[auto]': data.SquareLayout
        }"
        :style="style"
      >
        <nuxt-img
          v-if="data.DisableBackgroundCover"
          preset="standard"
          loading="lazy"
          :src="imageUrl"
        />
        <video
          v-if="
            data &&
              data.BackgroundMedia &&
              data.BackgroundMedia.Expanded?.ContentType &&
              data.BackgroundMedia.Expanded?.ContentType[0] === 'Video'"
          class="absolute top-0 left-0 h-full object-cover w-full bg-opacity-100 bg-transparent"
          muted
          loop
          autoplay
          playsinline
        >
          <source :src="data.BackgroundMedia.Expanded.Url" type="video/mp4">
        </video>
      </div>
      <div
        v-if="data"
        class="flex py-56 sm:p-56 flex-shrink-0 md:w-1/2"
        :class="{
          'md:order-first': data.MainBodyPosition === 'TopLeft',
          'md:items-center md:order-first':
            data.MainBodyPosition === 'LeftCenter',
          'md:items-end md:order-first': data.MainBodyPosition === 'BottomLeft',
          'md:items-center': data.MainBodyPosition === 'RightCenter',
          'items-end': data.MainBodyPosition === 'BottomRight',
          'px-24': data.BackgroundColor,
          'md:ml-12':
            (data.MainBodyPosition === 'TopRight' ||
              data.MainBodyPosition === 'RightCenter' ||
              data.MainBodyPosition === 'BottomRight') &&
            !data.BackgroundColor,
          'md:mr-12':
            (data.MainBodyPosition === 'TopLeft' ||
              data.MainBodyPosition === 'LeftCenter' ||
              data.MainBodyPosition === 'BottomLeft') &&
            !data.BackgroundColor,
        }"
        :style="{ backgroundColor: data.BackgroundColor }"
      >
        <!--
        <DebugOutput v-if="data" position="inline">{{ data.MainBody }}</DebugOutput>
-->
        <WysiwygWrapper
          v-if="data"
          :style-config="{
            'wysiwyg relative': true,
          }"
          data-epi-edit="MainBody"
          :html="data.MainBody"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type TextImageVideoBlockFragment } from '#gql';
import WysiwygWrapper from '~/components/WysiwygWrapper.vue';
const props = defineProps<{
  block: TextImageVideoBlockFragment,
  isMobile: boolean,
  isEditMode: boolean
}>();

const data = computed(() => {
  return props.block as TextImageVideoBlockFragment;
});

const style = computed(() => {
  if (data.value && data.value.BackgroundMedia?.Expanded?.Url && !data.value.DisableBackgroundCover) {
    return `background-image: url(${data.value.BackgroundMedia.Expanded?.Url})`;
  } else {
    return '';
  }
});

const imageUrl = computed(() => {
  return data.value?.BackgroundMedia?.Expanded?.Url ?? '';
});

</script>
<style scoped>
video {
  transform: translateZ(0);
}
</style>
